import React from "react"

import AnimationRevealPage from "helpers/AnimationRevealPage"
import Hero from "components/hero/TwoColumnWithPrimaryBackground"
import Features from "components/features/ThreeColWithSideImage"
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import FAQ from "components/faqs/SingleCol.js";
import ContactUs from "components/forms/SimpleContactUs"
import Footer from "components/footers/MiniCenteredFooter"

export default function Home() {
  return (
    <AnimationRevealPage disabled>
      <Hero />
      <Features />
      <Pricing />
      <Footer />
    </AnimationRevealPage>
  );
}
