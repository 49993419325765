import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import ERPIconImage from "images/erp.png";
import WebIconImage from 'images/web.png';
import CellphoneIconImage from 'images/cellphone.png';
import EcommerceIconImage from 'images/ecommerce.png';
import IntegrationsIconImage from "images/integrations.png";
import ConsultancyIconImage from 'images/consultancy.png';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-primary-500 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "¿Qué hacemos?", subheading = "Servicios", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   */

  const defaultCards = [
    {
      imageSrc: ERPIconImage,
      title: "ERP",
      description: "Automatiza y monitorea tu negocio de inicio a fin: Contabilidad, recursos humanos, CRM, compras, inventario, ..."
    },
    {
      imageSrc: WebIconImage,
      title: "Desarrollo web",
      description: "Herramientas en línea para tus colaboradores y tus clientes. Desarrollamos aplicaciones web a medida, rápidas, seguras y confiables"
    },
    {
      imageSrc: CellphoneIconImage,
      title: "Desarrollo móvil",
      description: "Tu negocio en la palma de una mano. Desarrollamos aplicaciones móviles a medida para Android y iOS"
    },
    {
      imageSrc: EcommerceIconImage,
      title: "E-Commerce",
      description: "Vende tus productos y servicios en cualquier parte del mundo: tienda en línea, pasarelas de pago, ..."
    },
    {
      imageSrc: IntegrationsIconImage,
      title: "Integraciones",
      description: "La comunicación es clave, incluso entre tus sistemas. Integramos hardware, aplicaciones en la nube, sistemas internos, ..."
    },
    {
      imageSrc: ConsultancyIconImage,
      title: "Consultorías",
      description: "¿Tienes problemas con tu sistema? Hacemos que sea más rápido, más seguro, más útil"
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
